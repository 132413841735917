export const localeConfig: configType = {
  SalmonSoftwareGatsby: {
    en: {
      locale: `en`,
      urlPrefix: ``,
      isoCode: `en-US`,
      zoho: {
        src: ``,
        domain: ``,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://salmon.puxdesign.net`,
        stage: ``,
        production: ``,
      },
      GTM: ``,
    },
  },
};

export const ptConfigs: PTConfigs = {
  PuxLandingPage: {
    codeName: `puxLandingPage`,
    componentName: `PuxLandingPage`,
    zones: [`flow`],
  },
  PuxHomePage: {
    codeName: `puxHomePage`,
    componentName: `PuxHomePage`,
    zones: [`flow`],
  },
  SalmonSoftwareInsight: {
    codeName: `salmonSoftwareInsight`,
    componentName: `SalmonSoftwareInsight`,
    zones: [`insightContentWidgets`, `flow`],
  },
  SalmonSoftwareCategory: {
    codeName: `salmonSoftwareCategory`,
    componentName: `SalmonSoftwareCategory`,
    zones: [`flow`],
  },
  SalmonSoftwareContactPage: {
    codeName: `salmonSoftwareContactPage`,
    componentName: `SalmonSoftwareContactPage`,
    zones: [],
  },
};

export const searchConfig: SearchConfig = {
  // SalmonSoftwareGatsby: {
  //   apiKey: `AIzaSyDe18pSt4eqcHdG93c1Hjfo08ZqbcqMF5Y`,
  //   apiUrl: `https://www.googleapis.com/customsearch/v1/siterestrict`,
  //   localizedOptions: {
  //     en: {
  //       index: `010400418469978324416:rc5-y9afa54`,
  //     }
  //   },
  // },
};

export interface automaticLocalizationConfigType {
  [site: string]: automaticLocalizationConfigItemType[];
}
export interface automaticLocalizationConfigItemType {
  id: string;
  url: string;
}
export interface configType {
  [site: string]: siteConfigType;
}
export interface siteConfigType {
  [lang: string]: langConfig;
}

export interface langConfig {
  locale: string;
  urlPrefix: string;
  isoCode: string;
  zoho: zohoConfig;
  domains: langDomain;
  GTM: string;
}

export interface langDomain {
  [domain: string]: string;
}

export interface zohoConfig {
  src: string;
  domain: string;
}

interface SearchConfig {
  [project: string]: {
    apiKey: string;
    apiUrl: string;
    localizedOptions: SearchLocalizedOptions;
  };
}

interface SearchLocalizedOptions {
  [locale: string]: {
    index: string;
  };
}

interface PTConfig {
  codeName: string;
  componentName: string;
  zones: string[];
}

interface PTConfigs {
  [key: string]: PTConfig;
}
