import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import './src/scss/style.scss'

import { initDynamicRS } from './src/utils/dynamicRS'
import { isPreviewByUrl } from 'Root/src/utils/preview/previewURL'
import { resetPreviewCookie, addPreviewCookie } from 'Root/src/utils/preview/usePreviewStatus'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onClientEntry = () => {
  console.log(`Init DynamicRS`)
  initDynamicRS()
  resetPreviewCookie()
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onInitialClientRender = () => {
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onRouteUpdate = () => {
  if (isPreviewByUrl(location.pathname)) {
    addPreviewCookie()
  } else {
    resetPreviewCookie()
  }
}
