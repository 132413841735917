exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-pux-home-page-pux-home-page-tsx": () => import("./../../../src/templates/PuxHomePage/PuxHomePage.tsx" /* webpackChunkName: "component---src-templates-pux-home-page-pux-home-page-tsx" */),
  "component---src-templates-pux-landing-page-pux-landing-page-tsx": () => import("./../../../src/templates/PuxLandingPage/PuxLandingPage.tsx" /* webpackChunkName: "component---src-templates-pux-landing-page-pux-landing-page-tsx" */),
  "component---src-templates-salmon-software-category-salmon-software-category-tsx": () => import("./../../../src/templates/SalmonSoftwareCategory/SalmonSoftwareCategory.tsx" /* webpackChunkName: "component---src-templates-salmon-software-category-salmon-software-category-tsx" */),
  "component---src-templates-salmon-software-contact-page-salmon-software-contact-page-tsx": () => import("./../../../src/templates/SalmonSoftwareContactPage/SalmonSoftwareContactPage.tsx" /* webpackChunkName: "component---src-templates-salmon-software-contact-page-salmon-software-contact-page-tsx" */),
  "component---src-templates-salmon-software-insight-salmon-software-insight-tsx": () => import("./../../../src/templates/SalmonSoftwareInsight/SalmonSoftwareInsight.tsx" /* webpackChunkName: "component---src-templates-salmon-software-insight-salmon-software-insight-tsx" */),
  "component---src-templates-salmon-software-search-results-salmon-software-search-results-tsx": () => import("./../../../src/templates/SalmonSoftwareSearchResults/SalmonSoftwareSearchResults.tsx" /* webpackChunkName: "component---src-templates-salmon-software-search-results-salmon-software-search-results-tsx" */)
}

